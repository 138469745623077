import baseConfig from './base';

const config = {
  ...baseConfig,
  awsCognitoAuth: {
    ...baseConfig.awsCognitoAuth,
    oauth: {
      ...baseConfig.awsCognitoAuth.oauth,
      redirectSignIn: 'https://admin.lightcastcc-qa.com', // Default Career Coach Frontend URL to redirect to after a SAML signin request
      redirectSignOut: 'https://admin.lightcastcc-qa.com' // Default Career Coach Frontend URL to redirect to after a SAML signout reques
    }
  }
};

export default config;
